<template>
  <div class="company-list">
    <vm-table
      ref="vmTable"
      :filter.sync="filter"
      url="customer-relationship/customer-company/page"
      @selection-change="handleSelectionChange">
      <el-button
        v-if="$auth('客户公司新增')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.editPage.open(0)">
        {{$l('common.add','新增')}}
      </el-button>
      <el-button
        v-if="$auth('客户公司合并')"
        slot="toolbar"
        type="primary"
        size="mini"
        @click="$refs.mergePage.open(selectList)">
        {{$l("common.add", "合并")}}
      </el-button>

      <el-button
        v-if="$auth('客户公司导入')"
        slot="toolbar"
        :loading="loading"
        type="primary"
        size="mini"
        @click="visible=!visible">
        导入
      </el-button>
      <template slot="adSearch">
        <div class="vm-search">
          <vm-search label="企业名称">
            <el-input v-model.trim="filter.name" clearable></el-input>
          </vm-search>
          <vm-search label="统一社会信用代码">
            <el-input v-model.trim="filter.creditCode" clearable></el-input>
          </vm-search>
          <vm-search label="省市地区">
            <vm-district-select
              :province-code.sync="filter.provinceCode"
              :city-code.sync="filter.cityCode"
              :area-code.sync="filter.districtCode">
            </vm-district-select>
          </vm-search>
          <vm-search label="公司类型">
            <vm-dict-select v-model="filter.type" type="customerCompanyType"></vm-dict-select>
          </vm-search>
          <vm-search label="公司联系人">
            <el-input v-model.trim="filter.contactName" clearable></el-input>
          </vm-search>
          <vm-search label="公司联系电话">
            <el-input v-model.trim="filter.contactPhone" clearable></el-input>
          </vm-search>
        </div>
      </template>
      <div slot="tree" :style="{width:width+'px'}" class="treeBox">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="企业名称" align="center">
        <template slot-scope="scope">
          {{scope.row.name}}{{scope.row.shortName?'('+scope.row.shortName+')':null}}
        </template>
      </el-table-column>
      <el-table-column prop="creditCode" label="统一社会信用代码" align="center"></el-table-column>
      <el-table-column prop="districtName" label="省市区" align="center"></el-table-column>
      <el-table-column prop="detailAddress" label="详细地址" align="center"></el-table-column>
      <el-table-column prop="typeDesc" label="公司类型" align="center"></el-table-column>
      <el-table-column prop="contactName" label="联系人" align="center"></el-table-column>
      <el-table-column prop="contactPhone" label="联系电话" align="center"></el-table-column>
      <el-table-column prop="maintenanceContractQuantity" label="合同数量" align="center"></el-table-column>
      <el-table-column prop="realEstateQuantity" label="楼盘数量" align="center"></el-table-column>
      <el-table-column prop="elevatorQuantity" label="在保台量" align="center"></el-table-column>
      <el-table-column :label="$l('common.function','操作')" align="center" class-name="vm-table_operate">
        <template slot-scope="scope">
          <el-button v-if="$auth('客户公司编辑')" type="primary" @click="$refs.editPage.open(scope.row.id)">{{$l('common.edit','编辑')}}</el-button>
          <el-button v-if="$auth('客户公司删除')" type="danger" @click="deleteRow(scope.row)">{{$l('common.delete','删除')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-page ref="editPage" @save-success="getList(-1)"></edit-page>
    <merge-page ref="mergePage" @save-success="getList(-1)"></merge-page>
    <el-dialog :visible.sync="visible" width="450px">
      <vm-drag-upload-file
        url="excelTemplate/usingCompany.xlsx"
        action="customer-relationship/customer-company/import"
        name="客户公司导入"
        download-name="使用单位.xlsx"
        @save-success="getList(-1)"></vm-drag-upload-file>
    </el-dialog>
  </div>
</template>
<script>
  import EditPage from "./Edit.vue";
  import MergePage from "./Merge";
  import {getWidth} from "@/util";

  export default {
    components: {EditPage,MergePage},
    data() {
      return {
        loading:false,
        filter: {
          name: "",
          creditCode: "",
        },
        selectList:[],
        data:[],
        width:200,
        visible:false,
      };
    },
    mounted() {
      this.getList(1);
      this.getTreeList();
    },
    methods: {
      getList(pageNum) {
        this.$refs.vmTable.getList(pageNum);
        this.visible=false;
      },
      handleSelectionChange(val) {
        this.selectList = val;
      },
      deleteRow(row) {
        this.$confirm(`确定删除${row.name}吗?`, this.$l("common.tip", "提示"), {type: "warning"}).then(() => {
          this.$http.delete("customer-relationship/customer-company", row.id).then(() => {
            this.getList(-1);
            this.$message.success(row.name + this.$l("common.deleteSuccessTip", "删除成功"));
          });
        });
      },
      getTreeList() {
        let filter = this.filter;
        if (this.filterFun) {
          let filterCopy = JSON.parse(JSON.stringify(filter));
          filter = this.filterFun(filterCopy);
        }
        let http =  this.$http.get("/basic/district/tree/locate-customer-company");
        http.then(data => {
          this.data = data;
          this.width=100+14*getWidth(data);
        }).catch(() => {
          this.status = "error";
        });
      },
      handleNodeClick(e) {
        this.filter.districtCode=e.value;
        this.getList(-1);
      },

    },
  };
</script>
<style lang="scss" scoped>
.treeBox{
  border: 1px solid #E6E6E6;
  padding: 10px;
  margin-right: 10px;
  width: calc(100% - 1300px);
  max-height: 750px;
  overflow-y: auto;
}
</style>
