<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?$l('company.edit','编辑企业'):$l('company.add','新增企业')"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('company.labelWidth','130px')">
      <el-form-item :label="$l('company.name','企业名称')" prop="name" :rules="$rule.notNull">
        <el-input v-model="model.name" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="企业简称" prop="shortName">
        <el-input v-model="model.shortName" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>

      <el-form-item label="统一社会信用代码" prop="creditCode">
        <el-input v-model="model.creditCode" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="省市区" prop="districtCode" :rules="$rule.notNull">
        <vm-district :area-code.sync="model.districtCode"></vm-district>
      </el-form-item>
      <el-form-item label="所属公司" prop="companyId" :rules="$rule.notNull">
        <el-select v-model="model.companyId" :placeholder="$l('common.enter','请输入')" :disabled="parentId">
          <el-option

            v-for="item in data"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址" prop="detailAddress">
        <el-input v-model="model.detailAddress" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="公司类型" prop="type">
        <vm-dict-select v-model="model.type" type="customerCompanyType"></vm-dict-select>
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="model.contactName" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="model.contactPhone" :placeholder="$l('common.enter','请输入')"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel", "取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save", "保存")}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
  import VmDistrict from "@/components/VmDistrictCascader";
  import loginUtil from "@/util/loginUtil";
  export default {
    components: {VmDistrict},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        data: [],
        options:[],
        model: {
          id: 0,
          name: "",
          shortName: "",
          type: "",
          creditCode: "",
          contactName: "",
          contactPhone: "",
        },
      };
    },
    mounted() {

    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getTreeList();
        this.getData();
      },
      getTreeList() {

        let http =  this.$http.get("/organization/company/page",{pageSize:100});
        http.then(data => {
          this.data = data.records.filter(item=>item.id===loginUtil.getCompanyCode()||item.parentId===loginUtil.getCompanyCode());
        }).catch(() => {
          this.status = "error";
        });
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`customer-relationship/customer-company/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("customer-relationship/customer-company/", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
